import { connect } from "react-redux";

import reducerRegistry from "../../reducerRegistry";
import { getBreadcrumbs } from "./actions";
import Component from "./breadcrumbs";
import { REDUCER_NAME } from "./constants";
import reducer, {
  makeSelectBreadcrumbs,
  makeSelectIsError,
  makeSelectIsReady,
} from "./reducer";

const makeMapStateToProps = () => {
  const selectBreadcrumbs = makeSelectBreadcrumbs();
  const selectIsReady = makeSelectIsReady();
  const selectIsError = makeSelectIsError();
  const mapStateToProps = (state, ownProps) => ({
    isReady: selectIsReady(state, ownProps),
    isError: selectIsError(state, ownProps),
    breadcrumbs: selectBreadcrumbs(state, ownProps),
  });

  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  onGetBreadcrumbs: (data) => dispatch(getBreadcrumbs(data)),
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);

reducerRegistry.register(REDUCER_NAME, reducer);
