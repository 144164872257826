import * as React from "react";
import { Link } from "react-router-dom";

import styles from "./style.scss";

const LABELS = {
  HOME: "Home",
};

function Component({
  breadcrumbs,
  isReady,
  isError,
  onGetBreadcrumbs,
  url,
  color,
}) {
  React.useEffect(() => {
    if (!isReady) {
      onGetBreadcrumbs({ url });
    }
  }, [onGetBreadcrumbs, isReady, url]);

  if (isError) {
    return null;
  }

  if (!isReady) {
    return null;
  }

  if (!(breadcrumbs && breadcrumbs.length)) {
    return null;
  }

  const style = { color };

  return (
    <div className={`${styles.breadcrumbs}`}>
      <ul className={styles.list}>
        {breadcrumbs.map(({ title, url: navUrl }, index) => (
          <li key={title} className={styles.item} style={style}>
            {url === navUrl ? (
              <span style={style}>{title}</span>
            ) : (
              <Link style={style} to={navUrl}>
                {index > 0 ? title : LABELS.HOME}
              </Link>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Component;
