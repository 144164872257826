import { fetchBreadcrumbs } from "../../../service/Navigation";
import { SET_BREADCRUMBS, SET_ERROR, getKey } from "./constants";

const setBreadcrumbs = (data, key) => ({
  type: SET_BREADCRUMBS,
  data,
  key,
});

const setError = (data, key) => ({
  type: SET_ERROR,
  data,
  key,
});

const getBreadcrumbs = (data) => (dispatch) =>
  fetchBreadcrumbs(data)
    .then((response) => {
      dispatch(setBreadcrumbs(response, getKey(data)));
      return response;
    })
    .catch((error) => {
      dispatch(setError(error, getKey(data)));
    });

// eslint-disable-next-line import/prefer-default-export
export { getBreadcrumbs };
