import * as React from "react";
import { NavLink } from "react-router-dom";

import styles from "./style.scss";

function TopNavigation({ navigation }) {
  const ulEl = React.useRef();

  React.useLayoutEffect(() => {
    if (ulEl.current) {
      const selectedEl = ulEl.current.querySelector(`.${styles.active}`);
      if (selectedEl) {
        ulEl.current.scrollLeft =
          selectedEl.offsetLeft -
          ulEl.current.offsetWidth / 2 +
          selectedEl.offsetWidth / 2;
      }
    }
  }, []);

  return (
    <div className={styles.container}>
      <ul className={styles.list} ref={ulEl}>
        {navigation.map((n) => (
          <li key={n.id} className={styles.item}>
            {n.isExternal ? (
              // eslint-disable-next-line react/jsx-no-target-blank
              <a
                href={n.url}
                className={styles.link}
                target={n.isNewWindow ? "_blank" : "_self"}
                rel={n.isNewWindow ? "noreferrer" : ""}
              >
                {n.title}
              </a>
            ) : (
              <NavLink
                to={n.url}
                className={({ isActive }) =>
                  `${isActive ? styles.active : ""} ${styles.link}`
                }
              >
                {n.title}
              </NavLink>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default TopNavigation;
