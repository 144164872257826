/* eslint-disable no-nested-ternary */
import * as React from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useForm } from "react-hook-form";

import styles from "./style.scss";

const LABELS = {
  SUCCESS: "Thank you for your submission.",
  ERROR: "An error occurred. Please try again later.",
};

function ContactForm({ id, type, fields, singleColumn }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    const response = await fetch(`/api/form/submit/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (response && response.ok) {
      setIsSuccess(true);
    } else {
      setIsError(true);
    }
  };

  const handleReCaptchaVerify = React.useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    await executeRecaptcha();
  }, [executeRecaptcha]);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  React.useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  return (
    <form
      className={styles.form}
      action="/api/form/submit/"
      method="POST"
      onSubmit={handleSubmit(onSubmit)}
    >
      {isSuccess && (
        <div className={styles.overlay}>
          <div className={styles.success}>
            <span>{LABELS.SUCCESS}</span>
          </div>
        </div>
      )}
      {isError && (
        <div className={styles.overlay}>
          <div className={styles.error}>
            <span>{LABELS.ERROR}</span>
          </div>
        </div>
      )}
      <input type="hidden" name="id" defaultValue={id} ref={register} />
      <input type="hidden" name="formType" defaultValue={type} ref={register} />
      {fields && Boolean(fields.length) && (
        <div
          className={`${styles.fields} ${
            singleColumn
              ? styles.columnsOne
              : fields.filter((n) => n.type !== "textarea").length % 2 === 0
              ? styles.columnsTwo
              : styles.columnsThree
          } ${isSuccess || isError ? styles.hide : ""}`}
        >
          {fields.map((n) => (
            <div
              key={n.name}
              className={n.type === "textarea" ? styles.fullWidth : undefined}
            >
              {n.values ? (
                <select
                  name={n.name}
                  ref={register({ required: n.isRequired })}
                  className={`${styles.input} ${styles.select}`}
                  defaultValue={0}
                >
                  <option key="0" value="" disabled>
                    Select {n.label}...
                  </option>
                  {n.values.map((v) => (
                    <option key={v} value={v}>
                      {v}
                    </option>
                  ))}
                </select>
              ) : n.type === "textarea" ? (
                <textarea
                  placeholder={n.label}
                  name={n.name}
                  type={n.type || "text"}
                  ref={register({ required: n.isRequired })}
                  className={`${styles.input} ${styles.textarea}`}
                  rows="3"
                />
              ) : (
                <input
                  placeholder={n.label}
                  name={n.name}
                  type={n.type || "text"}
                  ref={register({ required: n.isRequired })}
                  className={styles.input}
                />
              )}
              {errors[n.name] && (
                <span className={styles.required}>{n.label} is required.</span>
              )}
            </div>
          ))}
        </div>
      )}
      <input
        onSubmit={handleReCaptchaVerify}
        type="submit"
        disabled={isSubmitting}
        className={`${styles.button} ${
          isSuccess || isError ? styles.hide : ""
        }`}
      />
    </form>
  );
}

export default ContactForm;
